import { PropsWithChildren } from 'react';
import clsx from 'clsx';

export interface Body2Props {
  isBold?: boolean;
  textColor?: string;
  style?: object;
}

function Body2({
  children,
  isBold = false,
  textColor = 'text-primaryColor',
  style,
}: PropsWithChildren<Body2Props>) {
  return (
    <span
      className={clsx(
        'leading-tight',
        textColor,
        'text-sm',
        isBold ? 'bold' : 'normal'
      )}
      style={style}
    >
      {children}
    </span>
  );
}

export default Body2;
