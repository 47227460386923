export const imageDimension = (width: number, height: number): string => {
  return height > width ? 'vertical' : 'horizontal';
};

/**
 * normalized rgba, hex code and postcss variables depends on input
 * check whether input was color code or not,
 * if it is, return the free text, otherwise return postcss variable
 * @param {string} value
 * @returns {string} hexCode || rgbaValue || postcss variable
 */
export const getColorCode = (value: string): string => {
  if (value.indexOf('#') > -1 || /rgb/.test(value)) {
    return value;
  }

  return `var(--${value})`;
};

export const getCookieDomain = () => {
  const domain = window.location.host;
  const cookieDomain = process.env.NEXT_PUBLIC_COOKIE_DOMAIN ?? '';

  return domain.indexOf(cookieDomain) <= 0 ? domain : cookieDomain;
};

export const concatQueryParams = (
  useQueryParams: boolean,
  baseUrl: string,
  queryParams: { [key: string]: any }
): string => {
  if (
    !useQueryParams ||
    !queryParams ||
    Object.keys(queryParams).length === 0
  ) {
    return baseUrl;
  }

  const utmQuery: { [key: string]: any } = {};

  // Populate utmQuery only with keys not equal to 'slug'
  Object.entries(queryParams).forEach(([key, value]) => {
    if (key !== 'slug') {
      utmQuery[key] = value;
    }
  });

  // Return the base URL if utmQuery is empty
  if (Object.keys(utmQuery).length === 0) {
    return baseUrl;
  }

  const buttonURLObj = new URL(baseUrl);
  const params = new URLSearchParams(buttonURLObj.search);
  const paramsObj: { [key: string]: any } = {};

  // Convert URLSearchParams to an object
  params.forEach((value, key) => {
    paramsObj[key] = value;
  });

  // Merge existing params with utmQuery
  const mergeQuery = { ...paramsObj, ...utmQuery };

  // Convert final query object to a query string
  const mergeQueryString = Object.entries(mergeQuery)
    .map(
      ([key, value]) =>
        `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
    )
    .join('&');

  // Update the URL search params
  buttonURLObj.search = mergeQueryString;

  return buttonURLObj.toString();
};

export default {
  imageDimension,
  getColorCode,
  getCookieDomain,
  concatQueryParams,
};
