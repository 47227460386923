import { PropsWithChildren, useState, useEffect } from 'react';
import Link from 'next/link';
import useUTM from 'hooks/useUTM';

export interface BasicLinkProps {
  href?: string | null;
  locale?: string;
  passHref?: boolean;
  isExternalLink?: boolean;
  anchorStyle?: object;
  className?: string;
  dataTestId?: string;
  rel?: string;
  ariaLabel?: string;
  useUTMParams?: boolean;
}

function BasicLinkComponent({
  children,
  passHref = true,
  href,
  locale,
  isExternalLink,
  anchorStyle,
  className,
  dataTestId,
  rel,
  ariaLabel,
  useUTMParams = false,
}: PropsWithChildren<BasicLinkProps>) {
  const [isExternal, setIsExternal] = useState(false);
  const regex = /^https?:\/\//;
  let queryLink = useUTM(useUTMParams, href as any);
  if (!useUTMParams) {
    queryLink = href as any;
  }
  useEffect(() => {
    if (!window) return;
    const { hostname } = window.location;
    setIsExternal(Boolean(isExternalLink && !queryLink?.includes(hostname)));
  }, [queryLink, isExternalLink]);

  if (queryLink && regex.test(queryLink)) {
    return (
      <div className={className}>
        <a
          href={queryLink}
          style={anchorStyle}
          rel={`noopener noreferrer ${rel ?? ''}`}
          target={isExternal ? '_blank' : '_self'}
          data-testid={dataTestId}
          aria-label={ariaLabel}
        >
          {children}
        </a>
      </div>
    );
  }

  return (
    <div className={className}>
      <Link
        href={href || '/'}
        locale={locale}
        passHref={passHref}
        prefetch={false}
        style={anchorStyle}
        data-testid={dataTestId}
        aria-label={ariaLabel}
      >
        {children}
      </Link>
    </div>
  );
}

export default BasicLinkComponent;
