import clsx from 'clsx';
import showdown from 'showdown';
import LockIcon from '@public/icons/lock.svg';
export interface MarkdownProps {
  textAlign: React.CSSProperties['textAlign'];
  className?: string;
  fontSize?: React.CSSProperties['fontSize'];
  lineHeight?: React.CSSProperties['lineHeight'];
  md?: string;
  fontFamily?: React.CSSProperties['fontFamily'];
  hasLockIcon?: boolean;
  pt?: React.CSSProperties['paddingTop'];
  textColor?: React.CSSProperties['color'];
}

function Markdown({
  textAlign,
  className,
  fontSize,
  lineHeight,
  md,
  fontFamily,
  hasLockIcon = false,
  pt,
  textColor,
}: Readonly<MarkdownProps>) {
  const converter = new showdown.Converter({
    strikethrough: true,
    splitAdjacentBlockquotes: true,
    noHeaderId: true,
  });

  return (
    <div
      className={clsx(
        'markdown_main-container flex items-center justify-center',
        className
      )}
      style={{
        textAlign: textAlign,
        fontSize: fontSize,
        lineHeight: lineHeight,
        fontFamily: fontFamily,
        paddingTop: pt,
        color: textColor,
      }}
    >
      {hasLockIcon && <LockIcon width="10" height="12" viewBox="0 0 10 12" />}
      <div
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: converter.makeHtml(md!),
        }}
      />
    </div>
  );
}

export default Markdown;
