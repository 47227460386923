import { ImageProps } from 'next/image';

function placeholder(props: ImageProps) {
  const shimmer = (w: number, h: number) => `
<svg width="${w}" height="${h}" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
  <defs>
    <linearGradient id="g">
      <stop stop-color="#f6f7f8" offset="20%" />
      <stop stop-color="#edeef1" offset="50%" />
      <stop stop-color="#f6f7f8" offset="70%" />
    </linearGradient>
  </defs>
  <rect width="${w}" height="${h}" fill="#f6f7f8" />
  <rect id="r" width="${w}" height="${h}" fill="url(#g)" />
  <animate xlink:href="#r" attributeName="x" from="-${w}" to="${w}" dur="1.2s" repeatCount="indefinite"  />
</svg>`;

  const toBase64 = (str: string) =>
    typeof window === 'undefined'
      ? Buffer.from(str).toString('base64')
      : window.btoa(str);

  return {
    ...props,
    quality: props.quality ?? 80,
    placeholder: props.placeholder ?? (props.priority ? undefined : 'blur'),
    blurDataURL:
      props.blurDataURL ??
      (props.priority
        ? undefined
        : `data:image/svg+xml;base64,${toBase64(shimmer(700, 475))}`),
  };
}

export default placeholder;
