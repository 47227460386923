import { useLayoutEffect, useEffect, useState, useCallback } from 'react';

const useIsomorphicLayoutEffect =
  typeof window !== 'undefined' ? useLayoutEffect : useEffect;

const useMediaQuery = (width = 768) => {
  const [targetReached, setTargetReached] = useState(true);

  const updateTarget = useCallback((e: MediaQueryListEvent) => {
    setTargetReached(!!e.matches);
  }, []);

  useIsomorphicLayoutEffect(() => {
    if (!process.browser) {
      return;
    }
    if (!window) return;
    const media = window.matchMedia(`(max-width: ${width}px)`);
    media.addEventListener('change', updateTarget);

    if (!media.matches) {
      setTargetReached(false);
    }

    return () => media.removeEventListener('change', updateTarget);
  }, []);

  return [targetReached];
};

export default useMediaQuery;
