import Body1 from './Body1';
import Body2 from './Body2';
import Body3 from './Body3';
import BodyLink from './BodyLink';

export interface BodyProps {
  isBold?: boolean;
  textColor?: string;
}

export { Body1, Body2, Body3, BodyLink };
