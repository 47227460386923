import clsx from 'clsx';
import LockIcon from '@public/icons/lock.svg';

interface MarkdownProps {
  textAlign?: React.CSSProperties['textAlign'];
  textColor?: React.CSSProperties['color'];
  className?: string;
  md: string;
  hasLockIcon?: boolean;
}

export default function Markdown({
  textAlign,
  textColor,
  className,
  md,
  hasLockIcon = false,
}: Readonly<MarkdownProps>) {
  return (
    <div
      className={clsx(hasLockIcon && 'flex items-center', className)}
      style={{ textAlign, color: textColor }}
    >
      {hasLockIcon && (
        <LockIcon
          className="fill-white"
          width="10"
          height="12"
          viewBox="0 0 10 12"
        />
      )}
      <div
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: md,
        }}
      />
    </div>
  );
}
