import Separator from '@public/icons/separator.svg';
import { BreadcrumbItemsProp } from 'interfaces/cms/content';
import Link from 'components/common/Link';

function BreadcrumbItems({ id, name, urlPath }: BreadcrumbItemsProp) {
  return (
    <>
      <li className="text-sm text-primaryColor cursor-pointer" key={id}>
        {urlPath ? <Link href={urlPath}>{name}</Link> : name}
      </li>
      <li data-testid="separator">
        <Separator />
      </li>
    </>
  );
}

export default BreadcrumbItems;
