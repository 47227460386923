import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { concatQueryParams } from '../lib/helper';

const useUTM = (useQueryParams: boolean, buttonLink: string) => {
  const router = useRouter();
  const [buttonUrl, setButtonUrl] = useState<string>('');

  const updateUrlWithQueryParams = () => {
    const { query } = router;
    const updatedUrl = concatQueryParams(useQueryParams, buttonLink, query);
    setButtonUrl(updatedUrl as any);
  };
  useEffect(() => {
    updateUrlWithQueryParams();
  }, [router]);

  return buttonUrl;
};

export default useUTM;
