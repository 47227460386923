/* eslint-disable @next/next/no-before-interactive-script-outside-document */
import { useState } from 'react';
import Script from 'next/script';
import Home from '@public/icons/home.svg';
import Separator from '@public/icons/separator.svg';
import Link from 'components/common/Link';
import { BreadcrumbItemsProp, BreadcrumbProps } from 'interfaces/cms/content';
import BreadcrumbItems from './BreadcrumbItems';
import { baseUrl } from 'lib/constants';

function Breadcrumb({ background, items }: BreadcrumbProps) {
  const [showAllItems, setShowAllItems] = useState(false);

  const getStructuredData = () => {
    const structuredData = {
      '@context': 'https://schema.org',
      '@type': 'BreadcrumbList',
      itemListElement: [
        {
          '@type': 'ListItem',
          position: 1,
          name: 'Home',
          item: baseUrl,
        },
      ],
    };

    items?.forEach((item, idx) => {
      structuredData.itemListElement.push({
        '@type': 'ListItem',
        position: idx + 2,
        name: item.name,
        item: item.urlPath,
      } as never);
    });

    return structuredData;
  };

  const renderItems = () => {
    if (!items) return null;
    return !showAllItems && items.length > 2 ? (
      <>
        <BreadcrumbItems {...items[0]} />
        <li>
          <button
            className="text-sm text-primaryColor cursor-pointer"
            onClick={() => setShowAllItems(true)}
          >
            ...
          </button>
        </li>
        <li data-testid="separator">
          <Separator />
        </li>
        <li className="text-sm text-textColor" key={items[items.length - 1].id}>
          {items[items.length - 1].name}
        </li>
      </>
    ) : (
      <>
        {items.map((item: BreadcrumbItemsProp, idx) => {
          if (idx === items.length - 1) {
            return (
              <li className="text-sm text-textColor" key={item.id}>
                {item.name}
              </li>
            );
          }
          return <BreadcrumbItems {...item} key={item.id} />;
        })}
      </>
    );
  };

  return (
    <>
      <Script
        id="dynamicJsonLD"
        type="application/ld+json"
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: JSON.stringify(getStructuredData()),
        }}
        strategy="beforeInteractive"
      />

      <div
        className="max-w-[90rem] mx-auto my-0 lg:py-5 lg:px-[4.6rem] py-2 px-4"
        style={{ backgroundColor: background }}
      >
        <ul className="flex items-center flex-wrap gap-x-2.5 gap-y-1.5">
          <li data-testid="breadcrumb-home">
            <Link href="/" ariaLabel="home">
              <Home />
            </Link>
          </li>
          <li data-testid="separator">
            <Separator />
          </li>
          {renderItems()}
        </ul>
      </div>
    </>
  );
}

export default Breadcrumb;
