import { baseUrl } from './constants';

const baseSize = 16;

export const rem = (pxValue: number) => `${pxValue / baseSize}rem`;

export const getWebsiteUrl = (asPath: string) => {
  return `${baseUrl}${asPath.split('?')[0]}`;
};

export default { rem, getWebsiteUrl };
