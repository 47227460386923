import BaseImage, { ImageLoaderProps, ImageProps } from 'next/image';
import ImgixClient from '@imgix/js-core';
import placeholder from './placeholder';
import { useEffect, useRef, useState } from 'react';
import useMediaQuery from 'hooks/useMediaQuery';

const isProd = process.env.NEXT_PUBLIC_ENVIRONMENT === 'production';

const imgixBase = process.env.imgixBase || '';
const imgixDomain = process.env.imgixDomain || '';

const imageLoader = (
  { src, width, quality }: ImageLoaderProps,
  isMobile: boolean
) => {
  const client = new ImgixClient({ domain: imgixDomain });
  const config = {
    auto: 'compress,format',
    fit: 'clip',
    q: quality || (isMobile ? 30 : 50),
    dpr: typeof window !== 'undefined' ? window.devicePixelRatio : 1,
    fm: 'webp',
    w: isMobile ? Math.min(width, 768) : width,
  };

  const path = src.replace(imgixBase, '');
  return client.buildURL(path, config);
};

function Image({ priority, ...props }: ImageProps) {
  const [isAboveFold, setIsAboveFold] = useState(priority);
  const imgRef = useRef<HTMLImageElement | null>(null);
  const [isMobile] = useMediaQuery();

  useEffect(() => {
    const imgElement = imgRef.current;

    const observerCallback = (entries: IntersectionObserverEntry[]) => {
      const [entry] = entries;
      if (entry.isIntersecting) {
        setIsAboveFold(true);
      }
    };

    const observerOptions = {
      root: null,
      threshold: 0.1,
    };

    const observer = new IntersectionObserver(
      observerCallback,
      observerOptions
    );

    if (imgElement) {
      observer.observe(imgElement);
    }

    return () => {
      if (imgElement) {
        observer.unobserve(imgElement);
      }
    };
  }, []);

  if (isProd) {
    return (
      <BaseImage
        {...placeholder(props)}
        ref={imgRef}
        loader={(loaderProps) => imageLoader(loaderProps, isMobile)}
        loading={isMobile ? 'lazy' : isAboveFold ? 'eager' : 'lazy'}
        priority={!isMobile && (isAboveFold ? true : priority)}
      />
    );
  }

  return <BaseImage {...placeholder(props)} />;
}

export default Image;
