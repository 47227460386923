import clsx from 'clsx';

import ArrowDown from '@public/icons/angle-down.svg';

import { BodyLink } from 'components/common/Body';
import Link from 'components/common/Link';

import { NavigationMenu } from 'interfaces/cms/layout';

export interface NavigationProps {
  navigations: Array<NavigationMenu>;
  locale: string;
}

function Desktop({ navigations, locale }: Readonly<NavigationProps>) {
  return (
    <div
      className={clsx(
        'hidden',
        'lg:flex',
        'container',
        'max-w-full',
        'bg-white',
        'shadow-[0_0.0625px_0.125px_0_rgba(16, 102, 203, 0.05)]',
        'shadow-sm',
        'items-center',
        'justify-center'
      )}
    >
      <div
        className={clsx(
          'hidden lg:flex',
          'w-full',
          'max-w-[90rem]',
          'px-[1rem]',
          'lg:px-[2rem]',
          'xl:px-[3.75rem]',
          'flex-row',
          'justify-center'
        )}
      >
        {navigations.map((navigation, index) =>
          navigation.subcategory.length ? (
            <span
              className="hover:bg-accordionPanelBg py-[0.844rem] relative group"
              key={index}
              id={`menu-list-${index}`}
            >
              <div
                className={clsx(
                  'border-l border-grayColor border-dashed',
                  'flex flex-row items-center h-[2rem]',
                  index === navigations.length - 1 && 'border-r'
                )}
              >
                <BodyLink
                  textColor="text-navTextColor"
                  isBold
                  style={{ marginLeft: '1.875rem' }}
                >
                  {navigation.title}
                </BodyLink>
                <ArrowDown
                  id={`arrow-${index}`}
                  className={clsx(
                    'fill-navTextColor ml-[0.6rem] mr-[1.875rem]',
                    'transform transition duration-300 group-hover:rotate-180'
                  )}
                />
              </div>
              <div
                className={clsx(
                  'absolute mt-[0.85rem] w-[15.625rem] bg-white',
                  'rounded-bl-[1.25rem]',
                  'border border-navBarBorder',
                  'shadow-lg',
                  'shadow-[0_10px_20px_rgba(58, 111, 232, 0.20)] z-[15]',
                  'invisible group-hover:visible',
                  index === navigations.length - 1
                    ? 'right-0 rounded-br-[1.25rem]'
                    : 'rounded-r-[1.25rem]'
                )}
                id={`sub-menu-${index}`}
              >
                {navigation.subcategory.map((category, subMenuIndex) => (
                  <Link
                    href={category.link}
                    isExternalLink={category.type !== 'slug'}
                    locale={locale}
                    passHref
                    key={subMenuIndex}
                  >
                    <div
                      className={clsx(
                        'hover:bg-accordionPanelBg',
                        subMenuIndex === navigation.subcategory.length - 1 &&
                          'rounded-b-[1.25rem]',
                        subMenuIndex === 0 && 'rounded-tr-[1.25rem]'
                      )}
                      id={`subMenu-${index}-${subMenuIndex}`}
                    >
                      <div
                        className={clsx(
                          'py-[15px] mx-[15px]',
                          subMenuIndex !== navigation.subcategory.length - 1 &&
                            'border-b border-grayColor border-dashed',
                          'flex flex-row items-center'
                        )}
                      >
                        <span
                          className={`fill-navTextColor w-[0.9575rem] h-[0.8125rem] bg-[url('/icons/heart.svg')] bg-contain bg-no-repeat bg-center bg-origin-border`}
                        />
                        <span className="text-navTextColor pl-[0.625rem] text-sm">
                          {category.title}
                        </span>
                      </div>
                    </div>
                  </Link>
                ))}
              </div>
            </span>
          ) : (
            <span
              className="hover:bg-accordionPanelBg py-[0.844rem]"
              key={index}
              id={`menu-list-${index}`}
            >
              <div
                className={clsx(
                  'border-l border-grayColor border-dashed',
                  'flex flex-row items-center h-[2rem]',
                  index === navigations.length - 1 && 'border-r'
                )}
              >
                <Link
                  href={navigation.pageSlug || navigation.linkUrl}
                  locale={locale}
                  passHref
                  isExternalLink={
                    !!(navigation.linkUrl && navigation.linkUrl !== '/')
                  }
                  className="mx-[1.875rem]"
                >
                  <BodyLink textColor="text-navTextColor" isBold>
                    {navigation.title}
                  </BodyLink>
                </Link>
              </div>
            </span>
          )
        )}
      </div>
    </div>
  );
}

export default Desktop;
