import Head from 'next/head';
import { NextSeo, LogoJsonLd } from 'next-seo';
import useTranslation from 'next-translate/useTranslation';
import { useRouter } from 'next/router';
import { Seo } from 'interfaces/cms/page';

export function HeaderMeta({
  title,
  description,
  robots,
  ogImage,
  canonical,
  structuredData,
}: Seo) {
  const metaRobot = [];
  metaRobot.push(robots.split('_'));
  const { t } = useTranslation('common');
  const { locale } = useRouter();

  let canonicalUrl;
  if (typeof window !== 'undefined') {
    const { hostname, pathname } = window.location;
    canonicalUrl = `https://${hostname}${pathname}`;
  }

  return (
    <>
      <Head>
        <meta httpEquiv="content-language" content={locale} />
        {Boolean(structuredData) && (
          <script
            type="application/ld+json"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: JSON.stringify(structuredData) }}
          />
        )}
      </Head>

      <LogoJsonLd logo={t('structureData_logo')} url={t('structureData_url')} />
      <NextSeo
        title={title}
        description={description}
        noindex={metaRobot[0][0] === 'noindex'}
        nofollow={metaRobot[0][1] === 'nofollow'}
        canonical={canonical || canonicalUrl}
        openGraph={{
          title,
          description,
          images: [
            {
              width: ogImage ? ogImage.width : 1200,
              height: ogImage ? ogImage.height : 627,
              url: ogImage
                ? ogImage.url
                : `https://rabbitcare.com/images/og-image.jpg`,
              alt: ogImage ? ogImage.alt : 'Rabbit Care',
            },
          ],
          site_name: 'Rabbit Care',
        }}
      />
    </>
  );
}

export default HeaderMeta;
