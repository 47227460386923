import { PropsWithChildren, useEffect, useState } from 'react';
import clsx from 'clsx';

import ArrowDown from '@public/icons/angle-down.svg';
import LanguageIcon from '@public/icons/language.svg';

import Link from 'components/common/Link';

import { NavigationMenu } from 'interfaces/cms/layout';

export interface NavigationProps {
  navigations: Array<NavigationMenu>;
  locale: string;
  slug: string;
  isMultipleLang: boolean;
  isOpen: boolean;
  onClick?: () => void;
}

interface TitleProps {
  title: string;
  className?: string;
}

function Title({ title, className = '' }: Readonly<TitleProps>) {
  return (
    <span className={clsx('text-navTextColor font-bold text-base', className)}>
      {title}
    </span>
  );
}

function Container({
  children,
  onClickItem,
}: Readonly<PropsWithChildren<{ onClickItem: () => void }>>) {
  return (
    <div
      className={clsx(
        'py-[0.9375rem] px-[1.25rem]',
        'border-b border-grayColor',
        'flex flex-row items-center justify-between cursor-pointer'
      )}
      onClick={onClickItem}
      onKeyDown={(event) => {
        if (event.key === 'Enter') {
          onClickItem();
        }
      }}
    >
      {children}
    </div>
  );
}

function Mobile({
  navigations,
  locale,
  slug,
  isMultipleLang,
  isOpen,
  onClick,
}: Readonly<NavigationProps>) {
  const locales = [
    { id: 1, title: 'ภาษาไทย (ไทย)', code: 'th' },
    { id: 2, title: 'English (EN)', code: 'en' },
  ];

  const [menuOpenId, setMenuOpenId] = useState('');

  useEffect(() => {
    setMenuOpenId('');
  }, [isOpen]);

  const onOpenSubMenu = (id: string) => {
    setMenuOpenId(menuOpenId === id ? '' : id);
  };

  const handleSubMenuClick = () => {
    setMenuOpenId('');
    onClick?.();
  };

  return (
    <>
      {navigations.map((navigation) =>
        navigation.subcategory.length ? (
          <span key={navigation.id}>
            <Container
              onClickItem={() => onOpenSubMenu(navigation.id.toString())}
            >
              <Title title={navigation.title} />
              {navigation.subcategory.length > 0 && (
                <ArrowDown
                  className={clsx(
                    'fill-navTextColor',
                    menuOpenId === navigation.id.toString() && 'rotate-180'
                  )}
                />
              )}
            </Container>
            {menuOpenId === navigation.id.toString() &&
              navigation.subcategory.map((category, subMenuIndex) => (
                <div
                  key={category.id}
                  className={clsx('px-[1.25rem]', 'bg-accordionPanelBg')}
                  onClick={handleSubMenuClick}
                  onKeyDown={(event) => {
                    if (event.key === 'Enter') {
                      handleSubMenuClick();
                    }
                  }}
                >
                  <Link
                    href={category.link}
                    isExternalLink={category.type !== 'slug'}
                    locale={locale}
                    passHref
                    key={subMenuIndex}
                  >
                    <div
                      className={clsx(
                        'py-[0.9rem]',
                        subMenuIndex !== navigation.subcategory.length - 1 &&
                          'border-b border-borderColor border-dashed',
                        'flex flex-row items-center'
                      )}
                    >
                      <span
                        className={`fill-navTextColor w-[0.9575rem] h-[0.8125rem] bg-[url('/icons/heart.svg')] bg-contain bg-no-repeat bg-center bg-origin-border mr-2`}
                      />
                      <span className="text-navTextColor text-sm">
                        {category.title}
                      </span>
                    </div>
                  </Link>
                </div>
              ))}
          </span>
        ) : (
          <Link
            key={navigation.id}
            href={navigation.pageSlug || navigation.linkUrl}
            locale={locale}
            passHref
            isExternalLink={Boolean(
              navigation.linkUrl && navigation.linkUrl !== '/'
            )}
          >
            <Container
              {...{
                onClickItem: () => {
                  setMenuOpenId('');
                  onClick?.();
                },
              }}
            >
              <Title title={navigation.title} />
            </Container>
          </Link>
        )
      )}

      {isMultipleLang && (
        <Container key="language" onClickItem={() => onOpenSubMenu('language')}>
          <div className="flex flex-row items-center">
            <LanguageIcon />
            <Title title={locale} className="uppercase ml-3" />
          </div>
          <ArrowDown
            className={clsx(
              'fill-navTextColor',
              menuOpenId === 'language' && 'rotate-180'
            )}
          />
        </Container>
      )}
      {menuOpenId === 'language' &&
        locales.map((lang, index) => (
          <div
            key={`lang-${lang.code}`}
            className={clsx('px-[1.25rem]', 'bg-accordionPanelBg')}
          >
            <Link href={slug} locale={lang.code} passHref>
              <div
                className={clsx(
                  'py-[0.9rem]',
                  index !== locales.length - 1 &&
                    'border-b border-borderColor border-dashed',
                  'flex flex-row items-center'
                )}
              >
                <span
                  className={`fill-navTextColor w-[0.9575rem] h-[0.8125rem] bg-[url('/icons/heart.svg')] bg-contain bg-no-repeat bg-center bg-origin-border mr-2`}
                />
                <span className="text-navTextColor text-sm">{lang.title}</span>
              </div>
            </Link>
          </div>
        ))}
    </>
  );
}

export default Mobile;
