import Link from 'next/link';
import clsx from 'clsx';

interface LanguageButtonProps {
  text: string;
  code: string;
  dataTestId?: string;
  selected?: boolean;
  slug?: string;
}

function LanguageButton({
  text,
  code,
  dataTestId,
  selected,
  slug,
}: Readonly<LanguageButtonProps>) {
  if (!slug) {
    return null;
  }

  return (
    <Link
      href={slug}
      locale={code}
      role="button"
      data-testid={dataTestId}
      className={clsx(
        'w-[26px] h-[26px] sm:h-[1.875rem] sm:w-[1.875rem] md:h-[2.25rem] md:w-[2.25rem] cursor-pointer',
        'flex max-h-[2.625rem] justify-center items-center no-underline rounded-full',
        'p-2 border border-solid space-x-2',
        [
          selected
            ? 'bg-primary text-white border-primary'
            : 'bg-white text-primary border-muted-light hover:border-primary hover:bg-primary-light',
        ]
      )}
    >
      <span className="text-xs sm:text-sm font-medium">{text}</span>
    </Link>
  );
}

export default LanguageButton;
